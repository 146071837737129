import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Modal from "react-modal"

const Header = ({ siteTitle }) => {
  const [open_modal, setOpenModal] = useState(false)
  const Links = <div></div>
  return (
    <div class="px-6 py-2 ">
      <div class="container flex justify-between mx-auto">
        <div class="container overflow-x-hidden ">
          {" "}
          <Modal isOpen={open_modal}>
            <div class=" left-0 right-0 top-0  bg-white  rounded-br rounded-bl shadow z-10 w-full h-full">
              <div class="p-2 divide-y divide-gray-600 flex flex-col text-center">
                <Link
                  to="/ministry"
                  class="p-2 font-semibold hover:text-indigo-700"
                >
                  Ministries
                </Link>

                <Link
                  to="/resources"
                  class="p-2 font-semibold hover:text-indigo-700"
                >
                  Sermons
                </Link>

                <Link
                  to="/staff"
                  class="p-2 font-semibold hover:text-indigo-700"
                >
                  Staff
                </Link>

                <Link
                  to="/about"
                  class="p-2 font-semibold hover:text-indigo-700"
                >
                  What we believe
                </Link>

                <Link
                  to="/contact"
                  class="p-2 font-semibold hover:text-indigo-700"
                >
                  Contact
                </Link>
              </div>
            </div>
          </Modal>
          <nav class="flex items-center justify-between flex-wrap  p-6">
            <div class="flex items-center flex-shrink-0 text-white mr-6">
              <Link to="/">
                <StaticImage
                  src="../images/logo.png"
                  alt="Calvary chapel nairobi logo"
                />
              </Link>

              <Link to="/">
                <span class="font-semibold  md:block md:text-xl xs:text-sm tracking-tight text-black block hidden md:block ">
                  Calvary Chapel Nairobi
                </span>
              </Link>
            </div>
            <div class="block lg:hidden">
              <button
                class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                <svg
                  class="fill-black h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
            <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
              <div class="text-sm lg:flex-grow"></div>
              <div class="hidden md:block">
                <Link
                  to="/ministry"
                  className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
                >
                  Ministries
                </Link>

                <Link
                  to="/resources"
                  className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
                >
                  Sermons
                </Link>

                <Link
                  to="/staff"
                  className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
                >
                  Staff
                </Link>

                <Link
                  to="/about"
                  className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
                >
                   What we believe
                </Link>

                <Link
                  to="/contact"
                  className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
                >
                  Contact us
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
