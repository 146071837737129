import * as React from "react"
import PropTypes from "prop-types"

const Footer = () => (
  <footer class="bg-black pt-10 ">
    <div class="px-6 py-2 ">
      <div class="container flex justify-between mx-auto">
        <div class="container overflow-x-hidden ">
          <div class="max-w-6xl m-auto text-gray-800 flex flex-wrap items-center">
            <div class="p-10 xs:w-full  md:w-1/3  xs:w-full center-items bg-green">
              <div class="text-lg uppercasefont-medium mb-6 p-3 text-white	underline underline-offset-8 ">
                SERVICE TIMES
              </div>

              <a class="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700 p-2">
                Sundays: 10:00am - 12:00pm
              </a>
              <a class="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700 p-2">
                Wednesday Mid-Week (Prayers): 7:00pm
              </a>
            </div>

            <div class="p-10 xs:w-full  md:w-1/3">
              <div class="text-lg uppercase font-medium mb-6 p-3 text-white	underline underline-offset-8">
                CONTACT
              </div>

              <a
                href="https://goo.gl/maps/gqnP9yAvh8D2"
                class="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700 p-2"
                target={"_blank"}
              >
                Karen Plains Road, Academy of dance,Karen, Nairobi, Kenya.
              </a>

              <a
                href="#"
                class="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700 p-2"
              >
                Email: contact[@]ccnairobi.org
              </a>
            </div>

            <div class="p-10 xs:w-full  w-1/2  md:w-1/3 ">
              <div class="text-lg uppercase  font-medium mb-6 p-3 text-white underline underline-offset-8	">
                About
              </div>

              <a
                href="/about"
                class="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700 p-2"
              >
                About CCnairobi
              </a>
              <a
                href="/staff"
                class="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700 p-2"
              >
                About pastor Mike
              </a>
            </div>
          </div>

          <div class="pt-2">
            <div class="flex pb-5 px-3 m-auto pt-5 border-t border-gray-500 text-gray-400 text-sm flex-col md:flex-row max-w-6xl text-center">
              <div class="mt-2">
                © Calvary chapel 2022. All Rights Reserved.
              </div>

              <div class="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
                <a href="#" class="w-6 mx-1">
                  <i class="uil uil-facebook-f"></i>
                </a>
                <a href="#" class="w-6 mx-1">
                  <i class="uil uil-twitter-alt"></i>
                </a>
                <a href="#" class="w-6 mx-1">
                  <i class="uil uil-youtube"></i>
                </a>
                <a href="#" class="w-6 mx-1">
                  <i class="uil uil-linkedin"></i>
                </a>
                <a href="#" class="w-6 mx-1">
                  <i class="uil uil-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
